.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

th {
  border: 1px solid #ebedff;
  text-align: center;
}
td {
  border-left: 1px solid #ebedff;
  padding: 6px 0;
}

tr {
  border-radius: 10px;
}

table {
  width: 100%;
  border-radius: 20px !important;
  border-collapse: collapse;
  border: 1px solid #ebedff;
  text-align: center;
}

#L-E1:hover {
  cursor: pointer;
  opacity: 0.8;
  /* display: none; */
}

#L-E2:hover {
  cursor: pointer;
  opacity: 0.8;
  /* display: none; */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.injected-svg {
  width: 100% !important;
  height: 100% !important;
}

.injected-svg-2D-stratto{
  height: 100%;
  margin: auto;
}

#root {
  height: 100%;
  width: 100%;
}
.svg-container {
  display: flex;
  align-items: center;
}

.svg-container div {
  width: 100%;
  height: 100%;
}

.utiliti-stratto {
  cursor: pointer;
}

.utiliti-stratto:hover {
  opacity: 0.8;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: #222222;
}
::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 10px;
}

@supports (not (-moz-appearance: button)) and (contain: paint) and
  (-webkit-appearance: none) {
  .svg-container div {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
  }
}

@media only screen and (max-width: 1260px) {
  .svg-container div {
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .injected-svg {
    width: 100% !important;
    height: 100% !important;
  }

  .svg-container svg {
    display: block;
    height: 100%;
    width: unset;
  }

  .MuiSelect-icon {
    display: none !important;
  }

  .MuiButtonBase-root {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .MuiButtonBase-root input {
    opacity: unset;
  }
  .MuiGrid-root {
    width: 100%;
  }
  .MuiGrid-item {
    padding: 0;
  }
  .MuiChip-root {
    width: 100%;
  }

  .MuiMenu-list {
    color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  #menu2d {
    padding: 10px 25px;
  }

  .MuiPaper-root {
    width: 100% !important;
    max-height: unset !important;
    left: unset !important;
    max-width: unset !important;
  }

  .MuiPaper-root ul {
    padding: 6px 20px 0 20px !important;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .MuiPaper-root li {
    border-bottom: 1px solid #fff !important;
    padding: 22px 0;
  }

  .mobile-scroll {
    -webkit-overflow-scrolling: touch;
  }

  .MuiSvgIcon-root {
    display: none;
  }

  #demo-multiple-chip {
    padding: 10px 25px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Swiper CSS */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  /* background: #000; */
  color: #000;
}

/* Swiper */
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  color: rgba(0, 0, 0, 1) !important;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}

.swiper-button-prev::after {
  font-size: 13px !important;
}

.swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  color: rgba(0, 0, 0, 1) !important;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}

.swiper-button-next::after {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.swiper-slide {
  width: 25%;
  height: 100%;
}

.swiper-slide-thumb-active .swiperWrapper {
  border-color: rgba(70, 148, 156, 1);
  .overlayImage{
    color: rgba(70, 148, 156, 1) !important ;
  }
}

.swiperWrapper {
  border: 3px solid;
  border-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  overflow: hidden;
  height: 83px;
  position: relative
}

.overlayImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
}

